import { MessagesContext } from "contexts/messagesContext";
import { ReplyContext } from "contexts/replyContext";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { Alert, Input, Nav, NavItem, NavLink } from "reactstrap";
import { serviceGetChatById, serviceGetChats, serviceGetChatsByAdminStatus, servicePutChat } from "services/chats";
import { serviceGetContactbyIDS } from "services/contact";
import { getDepartment, getDepartmentByID } from "services/departments";
import { serviceGetFilterMessage } from "services/messages";
import { serviceGetTicketById, servicePutTickets } from "services/tickets";
import SimpleBar from "simplebar-react";
import { useDebounce } from "use-debounce";
import { Icon } from "../../../components/Component";
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import { ChatAsideBody } from "./ChatAsideBody";
import ChatBody from "./ChatBody";
import { ChatContext } from "./ChatContext";
import ChatModalFilter from "./ChatModalFilter";

import { socketIO } from "services/socket";
import { serviceGetTicketsById } from "services/tickets";
import lonceng4 from "../../../assets/sound/lonceng4.mp3";

const Chat = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [filterTab, setFilterTab] = useState("all");
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filteredMessageList, setFilteredMessageList] = useState([]);
  const [afterCursorSearchedMessage, setAfterCursorSearchedMessage] = useState({});
  const [filterText, setFilterText] = useState("");
  const [filterTag, setFilterTag] = useState([]);
  const [searchText] = useDebounce(filterText, 1000);
  const [mobileView, setMobileView] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [isModalTagAssignOpen, setIsModalTagAssignOpen] = useState(false);
  const [checkedTag, setCheckedTag] = useState([]);
  const [chatId, setChatId] = useState([]);
  const { chatState, chatIdState, isSavedArray } = useContext(ChatContext);
  const { messagesValue, statusValue, salesUpdate } = useContext(MessagesContext);
  const [newMessages, setNewMessages] = messagesValue;
  const [newSales] = salesUpdate;
  const [cookies] = useCookies(["user"]);
  const [isModalTagFilterOpen, setIsModalTagFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingOnce, setLoadingOnce] = useState(false);

  const [replyMessage, setReplyMessage] = useState(null);
  const [contextMessage, setContextMessage] = useState(null);
  const [afterCursorChat, setAfterCursorChat] = useState(null);
  const [assignMessage, setAssignMessage] = useState();

  const [chat, setChat] = chatState;
  const [chatIdList, setChatIdList] = chatIdState;
  const [isSaved, setIsSaved] = isSavedArray;
  const { search } = useLocation();
  const chatID = new URLSearchParams(search).get("chatID");

  let roleFunctions = JSON.parse(localStorage.getItem("permission"))["chats"].access || [];
  // console.log('roleFunctions', roleFunctions)

  const ALL_DEPT = "Access_All_Dept";
  const OWN_DEPT = "Access_Own_Dept";
  const OWN = "Access_Own";
  const BOT_ID = "3ef9f486-ddad-46ed-b568-f7b32a62262f";

  // if (cookies.role.name !== "SALES" && cookies.role.name !== "CS") {
  //   roleFunctions = roleFunctions.filter((rf) => rf !== "chat");
  // }
  const refreshChatList = async () => {
    const status = activeTab !== "all" ? activeTab.toUpperCase() : "";
    setIsLoading(true);
    // if (roleFunctions.includes(OWN)) {
    if (true) {
      const responseGetChats = await serviceGetChats(
        cookies.SIDikey,
        cookies.id,
        cookies.departmentIds,
        status,
        filterTag.toString(),
        roleFunctions,
        cookies.role.name
      );
      if (new RegExp(/20+/).test(responseGetChats.status)) {
        let data = responseGetChats.data.data;

        console.log(data)

        // save isSaved and contactID to context
        // let contactIds = data.map(item => item.contactId)
        // let contacts = await Promise.all(contactIds.map(id => serviceGetContact(cookies.SIDikey, id)));
        // contacts = contacts.filter((contact, index) => contact.data.id === contactIds[index])
        // let isSavedArray = contacts.map(contact => ({ id: contact.data.id, isSaved: contact.data.isSaved }));
        // setIsSaved(isSavedArray);

        const metaData = responseGetChats.data.meta;
        setAfterCursorChat(metaData);
        // sorting chat order by lastmessage timestamp
        try {
          if (data.length > 1) {
            //sorting
            data.sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp);
            //filtering chatData
            // data = data.filter((item)=>{return item.status !== 'RESOLVED'});
          }
        } catch (error) {
          console.log("unsort", error);
        }
        
        setChat(data);
        setChatIdList(
          data.map((item) => {
            return item.id;
          })
        );
        // setFilteredChatList(data);
      } else {
        console.log("Error get Message from DB", responseGetChats);
      }
    } else {
      const responseGetChats = await serviceGetChatsByAdminStatus(
        cookies.SIDikey,
        cookies.id,
        cookies.departmentIds,
        status,
        filterTag.toString(),
        roleFunctions
      );
      if (new RegExp(/20+/).test(responseGetChats.status)) {
        const data = responseGetChats.data.data;

        // save isSaved and contactID to context
        // let contactIds = data.map(item => item.contactId)
        // let contacts = await Promise.all(contactIds.map(id => serviceGetContact(cookies.SIDikey, id)));
        // contacts = contacts.filter((contact, index) => contact.data.id === contactIds[index])
        // let isSavedArray = contacts.map(contact => ({ id: contact.data.id, isSaved: contact.data.isSaved }));
        // setIsSaved(isSavedArray);

        const metaData = responseGetChats.data.meta;
        setAfterCursorChat(metaData);
        // sorting chat order by lastmessage timestamp
        try {
          if (data.length > 1) {
            data.sort((a, b) => b.lastMessage?.timestamp || 0 - a.lastMessage?.timestamp || 0);
          }
        } catch (error) {
          console.log("unsort", error);
        }

        setChat(data);
        setChatIdList(
          data.map((item) => {
            return item.id;
          })
        );
        // setFilteredChatList(data);
      } else {
        console.log("Error get Message from DB", responseGetChats);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isModalTagAssignOpen) {
      refreshChatList();
    }
  }, [ activeTab, filterTag]);

  // useEffect(() => {
  //   //Simpan data contact ke dalam context isSaved
  //   let contactIds = chat.map(item => item.contactId)

  //   if(contactIds.length > 0) {
  //     fetchContacts(contactIds);
  //   }
  // }, [chat]);

  useEffect(() => {
    //Simpan data contact ke dalam context isSaved
    let contactIds = chat.map((item) => item.contactId);

    if (contactIds.length > 0) {
      //fetchContacts(contactIds);
    }
  }, []);

  const fetchContacts = async (contactIds) => {
    const contacts = await serviceGetContactbyIDS(cookies.SIDikey, contactIds);
    //let contacts = await Promise.all(contactIds.map(id => serviceGetContact(cookies.SIDikey, id)));
    let newIsSavedArray = contacts.data.data.map((contact) => ({ id: contact?.id, isSaved: contact.isSaved }));
    setIsSaved((oldArray) => {
      const newArray = [...oldArray];
      newIsSavedArray.forEach((newItem) => {
        if (!newArray.some((oldItem) => oldItem.id === newItem.id)) {
          newArray.push(newItem);
        }
      });
      return newArray;
    });
  };

  // if the opened chat have new message
  const playSound = () => {
    try {
      const sound = new Audio(lonceng4);
      sound.play();
    } catch (error) {
      console.log(error);
    }
  };

  const refreshChatDataOnNewMessage = (chatData, newMessage) => {
    let newChats = [];
    let found = false;
    let foundIndex = -1;

    console.log('masuk refreshChatDataOnNewMessage')

    chatData.forEach((element, i) => {
      let ch = element;      

      if (!roleFunctions.includes('View_Chatbot') && newMessage.chat?.userId === BOT_ID) {
        if(selectedId === newMessage.chat?.id) setSelectedId(null);
      } else if (newMessage?.chat?.id === element.id) {
        console.log('eh sama', newMessage.chat.user);
        found = true;
        foundIndex = i;
        

        if (newMessage.chat?.user) {
          const newUser = newMessage.chat.user;
          ch.user = newUser;
          ch.userId = newMessage.chat.userId;
        }

        //jika tiket berpindah
        if (newMessage?.tickets) {
          ch.tickets = newMessage.tickets;
        }

        //jika tag berubah
        if (newMessage?.tags) {
          ch.tags = newMessage.tags;
        }
        
        if (newMessage?.chat?.tags) {
          ch.tags = newMessage?.chat?.tags;
        }

        if (newMessage.type !== "tag-update") {
          if (newMessage.type !== "tag-update-resolve") {
            ch.lastMessage = newMessage;

            //jika adalah chat biasa dan merupakan dari user
            if (!newMessage.fromMe) {
              try {
                playSound();
              } catch (error) {
                console.log(error);
              }
            }
          }
        }

        //ROLECHAT
        ch.unreadCount = newMessage?.chat?.unreadCount ? newMessage?.chat?.unreadCount : ch.unreadCount;
        ch.unreadCountAdmin = newMessage?.chat?.unreadCountAdmin ? newMessage?.chat?.unreadCountAdmin : ch.unreadCountAdmin;
        ch.unreadCountCs = newMessage?.chat?.unreadCountCs ? newMessage?.chat?.unreadCountCs : ch.unreadCountCs;
        ch.unreadCountHead = newMessage?.chat?.unreadCountHead ? newMessage?.chat?.unreadCountHead : ch.unreadCountHead;
        ch.unreadCountSuper = newMessage?.chat?.unreadCountSuper ? newMessage?.chat?.unreadCountSuper : ch.unreadCountSuper;
        ch.unreadCountAuditor = newMessage?.chat?.unreadCountAuditor ? newMessage?.chat?.unreadCountAuditor : ch.unreadCountAuditor;
        ch.unreadCountSysAdmin = newMessage?.chat?.unreadCountSysAdmin ? newMessage?.chat?.unreadCountSysAdmin : ch.unreadCountSysAdmin;

        //ROLECHAT
        ch.status = newMessage?.chat?.status || ch.status;
        ch.adminStatus = newMessage?.chat?.adminStatus || ch.adminStatus;
        ch.csStatus = newMessage?.chat?.csStatus || ch.csStatus;
        ch.headStatus = newMessage?.chat?.headStatus || ch.headStatus;
        ch.superStatus = newMessage?.chat?.superStatus || ch.superStatus;
        ch.auditorStatus = newMessage?.chat?.auditorStatus || ch.auditorStatus;
        ch.sysAdminStatus = newMessage?.chat?.sysAdminStatus || ch.sysAdminStatus;

        if (selectedId === newMessage.chatId) {
          let currentStatusObj = {};
          let currentStatus = "";
          let currentTicketStatusObj = null;

          switch(cookies.role.name){
            case "SALES":
              currentStatus = ch.status;
              currentStatusObj = {status: ch.status === 'UNOPENED' ? "OPENED" : ch.status, unreadCount: 0}
              currentStatusObj.csStatus = currentStatusObj.status;
              if(ch.userId === cookies.id){ // jika chat milik dirinya
                currentTicketStatusObj = {status: ch.status === 'UNOPENED' ? "OPENED" : ch.status}
              }
              ch.status = currentStatusObj.status;
              ch.unreadCount = 0;
              break
            case "CS":
              currentStatus = ch.csStatus;
              currentStatusObj = {csStatus: ch.csStatus === 'UNOPENED' ? "OPENED" : ch.csStatus, unreadCountCs: 0}
              currentStatusObj.status = currentStatusObj.csStatus;
              if(ch.userId === cookies.id){ // jika chat milik dirinya
                currentTicketStatusObj = {status: ch.csStatus === 'UNOPENED' ? "OPENED" : ch.csStatus}
              }
              ch.csStatus = currentStatusObj.csStatus;
              ch.unreadCount = 0;
              break
            case "SUPER ADMIN":
              currentStatus = ch.superStatus;
              currentStatusObj = {superStatus: ch.superStatus === 'UNOPENED' ? "OPENED" : ch.superStatus, unreadCountSuper: 0}
              ch.superStatus = currentStatusObj.superStatus;
              break
            case "HEAD":
              currentStatus = ch.headStatus;
              currentStatusObj = {headStatus: ch.headStatus === 'UNOPENED' ? "OPENED" : ch.headStatus, unreadCountHead: 0}
              ch.headStatus = currentStatusObj.headStatus;
              break
            case "AUDITOR":
              currentStatus = ch.auditorStatus;
              currentStatusObj = {auditorStatus: ch.auditorStatus === 'UNOPENED' ? "OPENED" : ch.auditorStatus, unreadCountAuditor: 0}
              ch.auditorStatus = currentStatusObj.auditorStatus;
              break
            case "SYSTEM ADMIN":
              currentStatus = ch.sysAdminStatus;
              currentStatusObj = {sysAdminStatus: ch.sysAdminStatus === 'UNOPENED' ? "OPENED" : ch.sysAdminStatus, unreadCountSysAdmin: 0}
              ch.sysAdminStatus = currentStatusObj.sysAdminStatus;
              break
            default:
              currentStatus = ch.adminStatus;
              currentStatusObj = {adminStatus: ch.adminStatus === 'UNOPENED' ? "OPENED" : ch.adminStatus, unreadCountAdmin: 0}
              ch.adminStatus = currentStatusObj.adminStatus;
              break
          }
          if (!roleFunctions.includes('View_Chatbot')) {
                servicePutChat(cookies.SIDikey, ch.id, { ...currentStatusObj });
                
                if(currentTicketStatusObj){ // jika ini dari cs atau sales, ticket harus di update
                // if(currentTicketStatusObj && currentTicketStatusObj.status !== item.tickets[0].status){ // jika ini dari cs atau sales, ticket harus di update
                  
                  if(ch.tickets && ch.tickets.length > 0){
                    // servicePutTickets(ch.tickets[0].id, cookies.SIDikey, { ...currentTicketStatusObj });
                    // ch.tickets[0].status = currentTicketStatusObj.status;
                    updateTicketStatusAndEmit(ch, cookies, currentTicketStatusObj, currentStatusObj, socketIO);
                  }
                }
              } else {
                if (currentStatus === "UNOPENED") {
                  servicePutChat(cookies.SIDikey, ch.id, {...currentStatusObj});
                }
              }
        }

        newChats = [{...ch}, ...newChats];
      } else {
        console.log("no action push")
        newChats.push(ch);
      }
    });

    return { newChats: newChats, found: found, foundIndex: foundIndex };
  };

  const updateTicketStatusAndEmit = async (ch, cookies, currentTicketStatusObj, currentStatusObj, socketIO) => {
    if (ch.tickets && ch.tickets.length > 0) {
      try {
        const response = await servicePutTickets(ch.tickets[0].id, cookies.SIDikey, { ...currentTicketStatusObj });
        
        if (response && response.status >= 200 && response.status < 300) {
          // Assuming status codes in the 200 range indicate success
          ch.tickets[0].status = currentTicketStatusObj.status;
  
          console.log('currentTicketStatusObj', currentTicketStatusObj);
          
          const updateTicketStatus = {
            type: "status-update",
            id: ch.id,
            ticketId: ch.tickets ? ch.tickets[0].id : ch.lastMessage.ticketId,
            chat: {
              ...currentStatusObj,
              id: ch.id,
              user: ch.user,
              channel: ch.channel
            },
            chatId: ch.id,
            fromMe: true,
            ...currentTicketStatusObj
          };
          
          socketIO.emit("admin", JSON.stringify(updateTicketStatus));
        } else {
          console.error('Failed to update ticket:', response);
        }
      } catch (error) {
        console.error('Error while updating ticket:', error);
      }
    }
  };

  useEffect(() => {
    if (!salesUpdate[0]) {
      return;
    }
    // console.log('ya terassign : ',salesUpdate);
    assignChatFromRotator(salesUpdate);
  }, [newSales]);

  const assignChatFromRotator = async (emitData) => {
    if (!Array.isArray(emitData)) {
      console.error('Invalid emitData: Expected an array');
      return;
    }
  
    let newChatSales = [];
    const updatedChat = [...chat]; // Clone current chat list to avoid direct mutations
  
    for (const salesArr of emitData) {
      if (!Array.isArray(salesArr)) {
        console.warn('Invalid salesArr: Expected an array');
        continue;
      }
  
      for (const sales of salesArr) {
        if (!sales || !sales.chat || !sales.chatId || !sales.ticket) {
          console.warn('Invalid sales object: Missing necessary properties');
          continue;
        }

        console.log({
          chatUser: sales.chat.user.email, chatName: sales.chat.name, dept: sales.department.name
        })
  
        const newChat = sales.chat;
        if (selectedId === newChat.id) {
          setSelectedId(null);
        }
  
        newChat.tickets = sales.ticket;
        newChat.lastMessage = sales.chat.messages[0];
        if (newChat.channel && sales.department) {
          newChat.channel.department = sales.department;
        }
  
        const targetChatIndex = updatedChat.findIndex((item) => item.id === sales.chatId);
  
        if (roleFunctions.includes(OWN)) { // If user has own/sales role
          if (sales?.chat?.user?.id === cookies.id) { // CHAT BUAT SALES INI
            await handleNewChatAssignment(sales, newChat, targetChatIndex, updatedChat, newChatSales);
          } else { // If the chat is for the current user
            console.log('anda bukan user', targetChatIndex, sales?.chat?.user?.id, cookies.id, sales?.chat?.user?.id === cookies.id);
            if (targetChatIndex >= 0) {
              updatedChat.splice(targetChatIndex, 1);
              setSelectedId(null);
            }
          }
        } else { // If user is not a sales representative
          updatedChat[targetChatIndex] = newChat
          updatedChat[targetChatIndex].tickets = sales.ticket;
          // await handleNonSalesAssignment(sales, updatedChat, targetChatIndex);
        }
      }
    }
  
    setChat([...newChatSales, ...updatedChat]);
  };
  
  async function handleNewChatAssignment(sales, newChat, targetChatIndex, updatedChat, newChatSales) {
    try {
      playSound();
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  
    if (targetChatIndex >= 0) {
      updatedChat[targetChatIndex] = newChat;
      updatedChat[targetChatIndex].tickets = sales.ticket;
      
      if (selectedId === updatedChat[targetChatIndex].id) {
        setSelectedId(null);
      }
    } else if(updatedChat.length === 0 || targetChatIndex < 0) { // jika chat tidak ditemukan atau tidak memiliki chat sebelumnya
      newChatSales.push(newChat);
      if (selectedId === newChat.id) {
        setSelectedId(null);
      }
    }
  }
  
  async function handleNonSalesAssignment(sales, updatedChat, targetChatIndex) {
    if (targetChatIndex >= 0) {
      const currentChat = updatedChat[targetChatIndex];
      if (selectedId === currentChat.id) {
        setSelectedId(null);
      }
  
      if (sales.userId !== currentChat.userId) {
        sales.user.department = sales.department;
        currentChat.user = sales.user;
        currentChat.userId = sales.userId;
      }
    }
  }

  useEffect(() => {

    //Jika ada pesan baru jika ada emit baru
    //unread refresh algorithm
    let newMessage = newMessages.at(-1);
    console.log('ada yang masuk nih', newMessage)
    if (newMessage === undefined) {
      return;
    }else if(newMessage.type === "status-update") {

      let currentChats = [...filteredChatList];
      
      // Step 2: Find the index of the item to be updated
      let indexToUpdate = currentChats.findIndex(cht => cht.id === newMessage.chatId);
      
      // Step 3: Check if the item exists in the list
      if (indexToUpdate !== -1) {
        // Step 4: Create a new list with the updated item
          let updatedChat = {
            ...currentChats[indexToUpdate], ...newMessage.chat
            // Add any properties from newMessage or other updates needed
          };
  
          // Step 5: Update the item in the list
          currentChats[indexToUpdate] = updatedChat;
          
          setFilteredChatList([...currentChats]);
          setChat([...currentChats]);
      }

      // Step 6: Set the updated currentChats list in setChat
      return;
    }

    // ASSIGN
    // jika kamu adalah SALES atau OWN USER
    if(roleFunctions.includes(OWN)){
      if(newMessage?.chat?.userId && newMessage?.chat?.userId !== cookies.id ){
        console.log('Kamu adalah Own User');
        try {
          removeChatById(newMessage?.chat?.id)
        } catch (error) {}
        return;
      }
    }
    
    // Jika bukan access all dept
    // jika emit bukan chat department miliknya
    if(!roleFunctions.includes(ALL_DEPT)){
      // jika itu bukan depts nya
      if(newMessage?.chat?.channel?.departmentId && !cookies.departmentIds.includes(newMessage?.chat?.channel?.departmentId)){
        console.log('Kamu bukan di dept ini');
        removeChatById(newMessage?.chat?.id);       
        return;
        //jika chat adalah bot session
      }
    }

    // SALES dan CS tidak menerima chatbot
    if(!roleFunctions.includes('View_Chatbot')){
      if(newMessage?.chat?.fromBot || newMessage?.chat?.userId === BOT_ID){
        removeChatById(newMessage?.chat?.id);      
        return;
      }
    }

    if (chat.length === 0) {
      addNewChatToChats(newMessage);
      return;
    }

    let addNewMessage = null;
    let newChats = null;
    let found = null;

    //jika ada filter chat di sebelumnya
    if (searchText) {
      addNewMessage = refreshChatDataOnNewMessage(filteredChatList, newMessage);
      newChats = addNewMessage.newChats;
      found = addNewMessage.found;
      if (found) {
        setFilteredChatList(newChats);
      }
    }else{
      addNewMessage = refreshChatDataOnNewMessage(chat, newMessage);
      newChats = addNewMessage.newChats;
      found = addNewMessage.found;
    }


    if (found) {
      const targetChat = newChats.find((itm) => itm.id === newMessage.chatId)
      if (newMessage.ticketId && targetChat.tickets) {
        if (targetChat.tickets[0].id !== newMessage.ticketId) {
          addNewTicketToChat(newChats, newMessage.ticketId, targetChat);
        } else {
          setChat([...newChats]);
        }
      } else {
        addNewTicketToChat(newChats, newMessage.ticketId, targetChat);
      }
    } else {
      if(chat && chat.length > 0){
        addNewChatToChats(newMessage);
      }else{
        refreshChatList();
      }
    }
    newMessages.pop();
  }, [newMessages]);



  const removeChatById = async (chatId) => {

    if(!chatId) return;

    // remove chat yang ada dalam kuasa bot
    let localChat = [...chat];
    localChat = localChat.filter((c)=> c.id !== chatId)
    setChat([...localChat])
    
    if(selectedId === chatId){
      setSelectedId(null);
    }
  };

  const addNewTicketToChat = async (newChats, newTicketId, targetChat) => {
    if (!newTicketId) return;
    const resNetTicket = await serviceGetTicketsById(cookies.SIDikey, newTicketId);
    const findTargetChatIndex = newChats.findIndex((itm) => itm.id === targetChat.id)
    newChats[findTargetChatIndex].tickets = resNetTicket.data.data;
    newChats[findTargetChatIndex].ticketId = resNetTicket.data.data[0].id;
    setChat([...newChats]);
  };

  const putSelectedChat = async (token, id, data) => {
    await servicePutChat(token, id, data);
  };

  const addNewChatToChats = async (newMessage) => {
    if (newMessage?.chat) {
      // emit coming from chat
      const isHaveTargetChat = chat.findIndex((ch) => ch.id === newMessage.chat.id);
      const newChat = newMessage.chat;
      newChat.lastMessage = newMessage;
      if(!newChat.tags) {
        const resNewChat = await serviceGetChatById(cookies.SIDikey, newChat.id);
        if (resNewChat.status === 200) {
          newChat.tags = resNewChat.data.tags
        } else {
          newChat.tags = []
        }
      }
      if (!newChat.channel && newChat.tickets) {
        const resDeptName = await getDepartmentByID(cookies.SIDikey, newChat.tickets[0].departmentId);
        if (resDeptName.status === 200) {
          newChat.channel = {
            department: {
                name:resDeptName.data.name,
                color:resDeptName.data.color
            }
          };
        }
      }

      if (newMessage.chat.user && newMessage.chat.user.id !== cookies.id) {
        // chat pindah sales lain
        if (cookies.role.name === "SALES") {
          if (isHaveTargetChat >= 0) {
            //const newChat = [...chat];
            chat.splice(isHaveTargetChat, 1);
            //setChat([...newChat]);
          }
        } else {
          if (isHaveTargetChat < 0) {
            const resNewChat = await getMissingChatData(newChat, newMessage);
            setChat([resNewChat, ...chat]);
          }
        }
      } else {
        if (isHaveTargetChat < 0) {
          const resNewChat = await getMissingChatData(newChat, newMessage);
          setChat([resNewChat, ...chat]);
        }
      }
    } else {
      // emit coming from rotator
      if (newMessage?.chat?.user?.id ?? newMessage.userId === cookies.id) {
        console.log('rotator agak kosong, cari manual');
        const resNewChat = await serviceGetChatById(cookies.SIDikey, newMessage.chatId);
        if (resNewChat.status === 200) {
          const resDeptName = await getDepartment(cookies.SIDikey, resNewChat.data.user.departmentId);
          if (resDeptName.status === 200) {
            const getCurrentDept = resDeptName.data.data.find((item) => item.id === resNewChat.data.user.departmentId);
            const newChat = resNewChat.data;
            const getTicketBychatId = await serviceGetTicketsById(cookies.SIDikey, newMessage.ticket[0].id);
            if (getTicketBychatId.status === 200) {
              newChat.tickets = getTicketBychatId.data.data;
              newChat.user.department = getCurrentDept;
              newChat.lastMessage.ticketId = newMessage.ticket[0].id;
              newChat.channel.department = newMessage.department
              setChat([newChat, ...chat]);
            }
          }
        }
      }
    }
    playSound();
  };

  const getMissingChatData = async (newChat, newMessage) => { // multi department bug
    const newChatData = newChat
    if (!newChat.tickets) {
      const resGetTickets = await serviceGetTicketById(cookies.SIDikey, newMessage.ticketId);
      if (resGetTickets.status === 200) {
        newChatData.tickets = [resGetTickets.data];
      }
    }
    if (!newChat.contactId) {
      const resGetChat = await serviceGetChatById(cookies.SIDikey, newMessage.chat.id);
      if (resGetChat.status === 200) {
        newChatData.contactId = resGetChat.contactId;
      }
    }
    //if (!newChat.user.department) {
    //  const resDeptName = await getDepartment(cookies.SIDikey, newChat.user.departmentId);
    //  if (resDeptName.status === 200) {
    //    const getCurrentDept = resDeptName.data.data.find((item) => item.id === newChat.user.departmentId);
    //    newChat.user.department = getCurrentDept;
    //  }
    //}
    if (!newChat.channel && newChat.tickets) {
      const resDeptName = await getDepartmentByID(cookies.SIDikey, newChat.tickets[0].departmentId);
      if (resDeptName.status === 200) {
        newChatData.channel = {
          department: {
              name:resDeptName.data.name,
              color:resDeptName.data.color
          }
        };
      }
    }
    return newChat
    //setChat([newChat, ...chat]);
  };
  // Filtering users by search
  useEffect(() => {
    if (searchText !== "") {
      const filteredObject = chat.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      });
      filterMessage();
      setFilteredChatList([...filteredObject]);
    } else {
      setFilteredChatList([]);
      setAfterCursorSearchedMessage(null);
    }
  }, [searchText, setFilteredChatList, setFilteredMessageList]);

  useEffect(() => {
    let newChats = [];
    chat.forEach((ch) => {
      if (ch.id === selectedId) {
        switch(cookies.role.name){
          case "SALES":
            ch.status = ch.status === 'UNOPENED' ? "OPENED" : ch.status;
            ch.unreadCount = 0;
            break
          case "CS":
            ch.csStatus = ch.csStatus === 'UNOPENED' ? "OPENED" : ch.csStatus;
            ch.unreadCountCs = 0;
            break
          case "SUPER ADMIN":
            ch.superStatus = ch.superStatus === 'UNOPENED' ? "OPENED" : ch.superStatus;
            ch.unreadCountSuper = 0;
            break
          case "HEAD":
            ch.headStatus = ch.headStatus === 'UNOPENED' ? "OPENED" : ch.headStatus;
            ch.unreadCountHead = 0;
            break
          case "ADMIN":
            ch.adminStatus = ch.adminStatus === 'UNOPENED' ? "OPENED" : ch.adminStatus;
            ch.unreadCountAdmin = 0;
            break
          case "AUDITOR":
            ch.auditorStatus = ch.auditorStatus === 'UNOPENED' ? "OPENED" : ch.auditorStatus;
            ch.unreadCountAuditor = 0;
            break
          case "SYSTEM ADMIN":
            ch.auditorSysAdmin = ch.auditorSysAdmin === 'UNOPENED' ? "OPENED" : ch.auditorSysAdmin;
            ch.unreadCountSysAdmin = 0;
            break
          default:
            break
        }
      }
      newChats.push(ch);
    });
    setChat([...newChats]);
  }, [selectedId]);

  const filterMessage = async (id) => {
    const responseGetMessages = await serviceGetFilterMessage(
      cookies.SIDikey,
      cookies.role.name,
      searchText,
      cookies.id
    );
    if (new RegExp(/20+/).test(responseGetMessages.status)) {
      const messageData = responseGetMessages.data.messages.data;
      const chatData = responseGetMessages.data.chats.data;
      chatData.sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp);
      const filteredChatData = chatData.filter((item) => {
        return !chatIdList.includes(item.id);
      });
      setChat((chat) => [...chat, ...filteredChatData]);
      setChatIdList((chatIdList) => [
        ...chatIdList,
        filteredChatData.map((item) => {
          return item.id;
        }),
      ]);
      setFilteredChatList(chatData);
      setFilteredMessageList(messageData);
      setAfterCursorSearchedMessage(responseGetMessages.data.messages.meta);
    } else {
      console.log("Error get Messages from DB", responseGetMessages);
    }
  };

  const onInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const onFilterClick = (prop) => {
    setFilterTab(prop);
  };

  const chatItemClick = (id) => {
    setSelectedId(id);
    if (window.innerWidth < 860) {
      setMobileView(true);
    }
  };

  useEffect(() => {
    if (chatID && !loadingOnce && chat && !isLoading) {
      chatItemClick(chatID);
      setLoadingOnce(true);
    }
  }, [isLoading]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    onFilterClick(tab);
  };

  return (
    <React.Fragment>
      <Head title="Chat / Messenger"></Head>
      <ContentAlt>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <SimpleBar className="">
              <div className="d-flex justify-content-center" style={{ height: "40px", marginLeft: "110px" }}>
                <Nav tabs style={{ border: "none", flexWrap: "nowrap" }}>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "all" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("all");
                      }}
                    >
                      All
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "unopened" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("unopened");
                      }}
                    >
                      Unopened
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "opened" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("opened");
                      }}
                    >
                      Opened
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ height: "40px", color: activeTab === "pending" ? "#4461F2" : "", cursor: "pointer" }}
                      onClick={() => {
                        toggleTab("pending");
                      }}
                    >
                      Pending
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        marginRight: "10px",
                        height: "40px",
                        color: activeTab === "resolved" ? "#4461F2" : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        toggleTab("resolved");
                      }}
                    >
                      Resolved
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </SimpleBar>
            <div className="nk-chat-aside-search p-2" style={{ backgroundColor: "#e5e9f2", marginBottom: "15px" }}>
              <div className="form-group">
                <div className="form-control-wrap d-flex">
                  <div className="form-icon form-icon-left">
                    <Icon name="search"></Icon>
                  </div>
                  <Input
                    type="text"
                    className="form-round"
                    id="default-03"
                    placeholder="Search by name"
                    onChange={(e) => onInputChange(e)}
                    style={{ backgroundColor: "white" }}
                  />
                  <div
                    className="btn btn-round btn-icon btn-light dropdown-toggle ml-2"
                    onClick={() => setIsModalTagFilterOpen(true)}
                  >
                    <ChatModalFilter
                      cookies={cookies}
                      isModalTagFilterOpen={isModalTagFilterOpen}
                      setIsModalTagFilterOpen={setIsModalTagFilterOpen}
                      setFilterTag={setFilterTag}
                      filterTag={filterTag}
                    />
                    <Icon name="filter-alt"></Icon>
                    {filterTag.length > 0 ? <span className="status dot dot-lg dot-success"></span> : <></>}
                  </div>
                </div>
              </div>
            </div>
            {filteredChatList ? (
              <ChatAsideBody
                cookies={cookies}
                onInputChange={onInputChange}
                chat={chat}
                setChat={setChat}
                chatId={chatId}
                setChatId={setChatId}
                setCheckedTag={setCheckedTag}
                isModalTagAssignOpen={isModalTagAssignOpen}
                setIsModalTagAssignOpen={setIsModalTagAssignOpen}
                filteredChatList={filteredChatList}
                setFilteredChatList={setFilteredChatList}
                afterCursorChat={afterCursorChat}
                setAfterCursorChat={setAfterCursorChat}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                chatItemClick={chatItemClick}
                filterTab={filterTab}
                isSearch={searchText !== "" ? true : false}
                filteredMessageList={filteredMessageList}
                setFilteredMessageList={setFilteredMessageList}
                afterCursorSearchedMessage={afterCursorSearchedMessage}
                setAfterCursorSearchedMessage={setAfterCursorSearchedMessage}
                searchText={searchText}
                isLoading={isLoading}
                status={activeTab !== "all" ? activeTab.toUpperCase() : ""}
                filterTag={filterTag}
                setContextMessage={setContextMessage}
                roleFunctions={roleFunctions}
              />
            ) : (
              <></>
            )}
          </div>
          {selectedId !== null ? (
            <ReplyContext.Provider
              value={{ value: [replyMessage, setReplyMessage], value2: [contextMessage, setContextMessage] }}
            >
              <ChatBody
                AfterAssign={() => refreshChatList()}
                cookies={cookies}
                id={selectedId}
                mobileView={mobileView}
                setMobileView={setMobileView}
                setSelectedId={setSelectedId}
                setIsModalTagAssignOpen={setIsModalTagAssignOpen}
                isModalTagAssignOpen={isModalTagAssignOpen}
                chatId={chatId}
                setChatId={setChatId}
                checkedTag={checkedTag}
                setCheckedTag={setCheckedTag}
                assignMessage={assignMessage}
                filteredChatList={filteredChatList}
                setFilteredChatList={setFilteredChatList}
              />
            </ReplyContext.Provider>
          ) : (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-icon">
                  <Icon name="chat" className="icon-circle icon-circle-xxl bg-white"></Icon>
                </div>
                <div className="nk-chat-blank-btn">
                  <h6>Please select a chat to start messaging</h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </ContentAlt>
    </React.Fragment>
  );
};

export default Chat;
